import '../components/aboutus.css';
import Contactus from './Contactus';

function About() {
  return (
    <>

      <div className="textcontainer">
      <div id='videobox'>
        <video id="myVideo" autoPlay muted loop  controls>
          <source src="/images/source/v3.mp4" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
        <p>
        <br/>
        Ayurveda holds immense importance as a holistic system of medicine that has been practiced for thousands of years. Its emphasis on balance and harmony within the body, mind, and spirit sets it apart. Here are some key aspects of the importance of Ayurveda and the quality services provided by our clinic:<br/><br/>
<strong>1.	Holistic Well-being:</strong> Ayurveda is not just about treating symptoms; it focuses on the overall well-being of an individual. It addresses the root cause of an ailment, taking into account physical, mental, and spiritual aspects.<br/>
<strong>2.	Personalized Approach:</strong> Ayurvedic treatments are highly individualized, considering a person's unique constitution (Prakriti), current imbalances (Vikriti), and specific health needs. This personalized approach ensures targeted and effective care.<br/>
<strong>3.	Preventive Healthcare:</strong> Ayurveda places a strong emphasis on preventive healthcare. Through lifestyle recommendations, dietary guidelines, and rejuvenating therapies, it aims to maintain health and prevent diseases.<br/>
<strong>4.	Natural Healing:</strong> Ayurvedic treatments predominantly use natural substances, such as herbs, minerals, and oils, to promote healing. This aligns with the body's natural processes, minimizing side effects.<br/>
<strong>5.	Quality Services at Our Clinic:</strong> Our clinic prioritize quality in every aspect of service delivery.<br/>
<ul className="b">
<li><strong>Expert Practitioner: . Vaidya. Madhuri Shete</strong>, a consultant Ayurvedic physician with a Master's degree in Ayurveda from Pune is an experienced and qualified Ayurvedic practitioner who brings deep knowledge and expertise to every consultation.</li>
<li><strong>Customized Treatment Plans:</strong> Each patient receives a customized treatment plan tailored to their unique needs and health goals.</li>
<li><strong>Traditional Therapies:</strong> Our clinic offer a range of traditional Ayurvedic therapies, ensuring an authentic and effective healing experience.</li>
<li><strong>Patient-Centric Approach:</strong> We prioritize the well-being and satisfaction of our patients. A patient-centric approach guides our interactions and treatments.</li>
<li><strong>Hygiene and Safety:</strong> We maintain high standards of hygiene and safety in our clinic, ensuring a clean and secure environment for our patients.</li><br/><br/>
</ul>
In essence, the importance of Ayurveda lies in its ability to offer a comprehensive, personalized, and natural approach to health. Our clinic, Achyutam Ayurveda & Panchakarma through its quality services, strive to uphold these principles and provide effective and authentic Ayurvedic care to those seeking holistic well-being.
<strong>Experience the rejuvenating influence of Ayurveda and Panchakarma with Achyutam Ayurveda & Panchakarma, revitalizing your mind, body, and soul.</strong>
        <br /></p></div>

      {/* <div className="textcontainer1">
        <p>So Start Your Child’s Suvarna Prashan Journey Now & Provide Your Child With A Strong Foundation For A Healthy And Thriving Future.
        </p>
      </div> */}
      {/* <div className="whatsapp"><a href="https://api.whatsapp.com/send?phone=+919082296568">
        <img className="whatsappus1" src="/images/Whatsapp-icon.png"></img></a></div> */}



<Contactus></Contactus>
    </>
  );
}

export default About;
