import '../components/panchakarma.css';

function Panchakarma() {
  return (
    <>
      <div className="textcontainer">
        <p>
          <div className='italic1'>"<span className='fword'>Panchakarma</span>," derived from Sanskrit, translates to "Five Therapies." These five therapeutic treatments, namely Vamana, Virechana, Nasya, Basti, and Raktamokshana, are meticulously designed to eliminate toxins from the body.
          To ensure optimal results, each Panchakarma therapy necessitates adherence to specific pre and post-therapeutic protocols, known as Purva Karma and Paschat Karma, complemented by carefully curated diet regimens.
          Embark on a journey of holistic well-being as you engage in these time-tested practices that purify and rejuvenate the body, fostering a profound sense of health and vitality.
          </div>
          <br /><br />
          <div id="vaman">
            <strong className='subheadings'>Vaman [Emesis Therapy]: </strong><br />
            <div><img className='image1' src='/images/pics/vamana (2).png'></img></div>
            <div className='topspace1'><strong>Procedure :</strong> This therapeutic procedure involves the administration of medicated Ghruta (clarified butter) to the patient after a comprehensive assessment of Dosha, Vyadhi, and Prakriti. Spanning 5-7 days, the treatment includes Snehan (oleation) and Swedan (sudation). Following a one-day rest period (Vishranti), a medicated emesis is performed.</div>
            <div className='topspace1'><strong>Duration :</strong> This carefully curated process unfolds over a span of 8-10 days, offering targeted benefits.</div>
            <div className='topspace1'><strong>Indications :</strong> It efficiently eliminates excess and detrimental Kapha from the body, providing relief for conditions associated with Kapha imbalance.</div>
            Conditions such as constipation, hyperacidity, digestive disorders, jaundice, chronic headache, migraine, diabetes, asthma, as well as various skin disorders including acne, herpes, vitiligo, psoriasis, eczemas, and gynecological disorders, find targeted alleviation through this therapeutic approach.

            Embark on a transformative journey towards well-being as this procedure, tailored to address Pitta imbalances, provides a holistic solution to specific health concerns.
          </div>
          <br /><br />
          <div id="virechan">
            <strong className='subheadings'>Virechan [Purgation Therapy]: </strong><br />
            <div><img className='image1' src='/images/pics/virechanaa.png'></img></div>
            <div className='topspace1'></div><strong>Procedure :</strong>This specialized procedure involves the administration of medicated Ghruta (clarified butter) to the patient following a thorough assessment of Dosha, Vyadhi, and Prakriti. Over a duration of 5-7 days, this comprehensive treatment includes Snehan (oleation) and Swedan (sudation), followed by a Medicated Purgation Therapy.<br />
            <div className='topspace1'></div><strong>Duration :</strong>Typically spanning 8-10 days, this procedure is meticulously designed to address Pitta-related concerns. <br />
            <div className='topspace1'></div><strong>Indications :</strong>It effectively removes excess and adverse Pitta from the body, offering relief for a spectrum of Pitta ailments. <br />
            Conditions such as bronchial asthma, chronic allergies, vitiligo, psoriasis, hyperacidity, chronic indigestion, nasal congestion, edema, obesity, psychological disorders, and various skin disorders find alleviation through this transformative procedure.

            Embark on a journey of restoration and balance, tailored to address specific health concerns and deliver a profound sense of well-being.
          </div>
          <br /><br />
          <div id="basti">
            <strong className='subheadings'>Basti [Enema Therapy] :  </strong><br />
            <div><img className='image1' src='/images/pics/basti12.png'></img></div>
            <strong>Basti, or enema therapy, holds a significant place in Ayurveda and is often considered as half the journey to recovery. Once you undergo Basti treatment, you are already halfway towards curing your ailment. This versatile therapy is applicable to a wide range of conditions, be they acute or chronic, simple or complicated.</strong>
            <br />
            <div className='topspace1'></div><strong>Procedure :</strong>Administered as medicated oil or Kadha, Basti is given as an enema in conjunction with Snehan (oleation) and Swedan (sudation). Its effectiveness lies in the rapid absorption of medicines by the large intestines, rendering it a swifter mode of action compared to oral medications.
            <br />
            Basti comes in various types, each tailored to specific contents used. The main types include Niruha Basti, Anuvasan Basti, Tiktaksheer Basti, Yaapan Bastis, and Matra Basti.
            <br />
            <div className='topspace1'></div><strong>Duration :</strong>The duration of this treatment varies, ranging from 8, 16, to 30 days, depending on the medical condition of the individual.<br />
            <div className='topspace1'></div><strong>Indications :</strong>It effectively removes excess and adverse Pitta from the body, offering relief for a spectrum of Pitta ailments. <br />
            This comprehensive therapy finds application in a myriad of Vata imbalance disorders such as hemiplegia, paraplegia, arthritis, cervical spondylosis, lumbar spondylosis, disc prolapse or degeneration, irritable bowel syndrome, chronic colitis, constipation, digestive disorders, piles, backache, sciatica, hepatomegaly, splenomegaly, obesity, sexual debility, infertility, multiple abortions, and more.
            Embark on a tailored path to healing with Basti, addressing Vata imbalances for a holistic approach to well-being.
          </div>
          <br /><br />
          <div id="nasya">
            <strong className='subheadings'>Nasya :</strong><br />
            <div><img className='image1' src='/images/pics/nasyyaa.png'></img></div>
            <strong>In Ayurveda, the nose is regarded as Shirodwar, the opening to the brain. Consequently, the administration of medicine through Nasya is deemed highly effective and rapid in action.
            Nasya's versatility lies in its ability to work swiftly, either by cleansing accumulated Kapha toxins from the head and neck region, eliminating the imbalance of local Doshas, or nourishing the internal parts of the head.</strong>
            <br />
            <div className='topspace1'></div><strong>Procedure :</strong>During this procedure, medicated oil, ghee, Kadha, or Churna are delicately introduced through the nasal cavity.
            <br />
            <div className='topspace1'></div><strong>Duration :</strong>The duration of Nasya treatment can extend up to 30 days, depending on the nature of the disease.<br />
            <div className='topspace1'></div><strong>Indications :</strong>This therapeutic approach finds application in a diverse range of diseases, including trigeminal neuralgia, Bell's palsy, insomnia, sinusitis, migraines, headaches of various origins, nasal allergies, nasal polyps, hyperpigmentation in the face, premature graying of hair, clarity of voice, loss of smell and taste, frozen shoulder, and stiffness of the neck.
            <br />Embark on a path to holistic well-being as Nasya addresses a spectrum of health concerns, offering effective relief and promoting balance in the intricate system of the head and neck.
          </div>
          <br /><br />
          <div id="raktamokshan">
            <strong className='subheadings'>Raktamokshan :</strong><br />
            <div><img className='image1' src='/images/pics/raktamokshanaa.png'></img></div>
            <strong>Bloodletting is a potent therapy for purifying the blood, involving the controlled removal of small quantities of blood to neutralize accumulated adverse Pitta. While there are various methods of bloodletting, the practical approaches often involve removal by syringes and the application of leeches due to their safety.</strong><br />
            <div className='topspace1'></div><strong>Procedure :</strong>During this procedure, medicated oil, ghee, Kadha, or Churna are delicately introduced through the nasal cavity.
            <br />
            <div className='topspace1'></div><strong>Duration :</strong>3-7 days<br />
            <div className='topspace1'></div><strong>Indications :</strong>This therapeutic intervention is employed for a spectrum of conditions associated with Pitta imbalance. It proves beneficial for Pitta diseases, eczemas, and other skin disorders, as well as conditions such as gout, edema, inflammations, hypertension, abscesses, and severe pains and stiffness anywhere in the body.
            <br />Experience the effectiveness of bloodletting as it contributes to blood purification, providing relief for a range of health concerns related to Pitta imbalances and associated ailments.
          </div>

          <br />
        </p>
      </div>

      <div className="textcontainer1">
        <p>Experience the rejuvenating influence of Ayurveda and Panchakarma with Achyutam Ayurveda & Panchakarma, revitalizing your mind, body, and soul.
        </p>
      </div>
      {/*<div className="whatsapp"><a href="https://api.whatsapp.com/send?phone=+918788083759">
        <img className="whatsappus" src="/images/WhatsappUs.png"></img></a></div> */}




    </>
  );
}

export default Panchakarma;
