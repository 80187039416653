import React, { useState } from 'react';
import axios from 'axios';
import '../components/servicesconeectionsection.css';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Contactus from './Contactus';

const ServicesConnectionsection = () => {



  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [location, setLocation] = useState('');

  const handleChange = (e) => {
    e.preventDefault();
    console.log(name);

    const data = {
      Name: name,
      Email: email,
      Number: number,
      Location: location
    }
    axios.post('https://sheet.best/api/sheets/fcd81388-7867-40b7-ac55-bbbded30c3f0', data).then((response) => {
      console.log(response);
      setName('');
      setEmail('');
      setNumber('');
      setLocation('');
    })
  }


  return (
    <>
      <div className='style'><h3>Treatments Provided</h3></div>
      <div className="container1 py-2" >
        <div className="row">
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
                <img className='' src="/images/images/newimages/drive-download-20240225T135402Z-001/vaman123.png" alt="" /><br />
                <div className='text-overlay'>“Cleanse, Rejuvenate, Thrive – Ayurvedic Wellness Through Therapeutic Emesis."</div>
                <div className="">
                  <HashLink className="hlink" to="/panchakarma#vaman">Vaman</HashLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
                <img src="/images/images/clinic images-20240122T082942Z-001/clinicimages/virechan.jpg" /><br />
                <div className='text-overlay'>“Detoxify Your Way to Renewed Vitality – Ayurvedic Purgation for Holistic Well-being."</div>
                <div className=""><HashLink className="hlink" to="/panchakarma#virechan">Virechan</HashLink></div></div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1 rightside">
                <img src="/images/images/newimages/drive-download-20240225T135402Z-001/basti123.png" /><br />
                <div className='text-overlay'>“Nourish, Cleanse, and Restore – Ayurvedic Enemas for Deep Healing and Balance."</div>
                <div className=""><HashLink className="hlink" to="/panchakarma#basti">Basti</HashLink></div></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
              <img src="/images/images/clinic images-20240122T082942Z-001/clinicimages/nasya.png" /><br />
                <div className='text-overlay'>“Breathe Wellness, Elevate Vitality – Ayurvedic Nasal Therapy for Clarity and Balance."</div>
                <HashLink className="hlink" to="/panchakarma#nasya">Nasya</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
              <img src="/images/images/clinic images-20240122T082942Z-001/clinicimages/raktamokshan.png" /><br />
                <div className='text-overlay'>“Purify Your Lifeblood, Embrace Vitality – Ayurvedic Bloodletting for Holistic Wellness."</div>
                <HashLink className="hlink" to="/panchakarma#raktamokshan">Raktamokshan</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 rightside text-center">
            <div className='bg py-3'>
              <div className="serviceone1 rightside">
              <img src="/images/images/newimages/drive-download-20240225T135402Z-001/snhean swedaan.png" alt="" /><br />
                <div className='text-overlay'>“Immerse in Ayurvedic Bliss, Unveiling the Perfect Harmony of Soothing Oils and  Therapeutic Steam."</div>
                <HashLink className="hlink" to="/treatments#snehanswadan">Abhyanga/Snehan - Swedan</HashLink></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
              <img src="/images/images/clinic images-20240122T082942Z-001/clinicimages/Udvartana.jpg" /><br />
                <div className='text-overlay'>“Unveil Radiant Skin, Renewed Energy – Ayurvedic Powder Massage for Body and Soul."</div>
                <HashLink className="hlink" to="/treatments#udvartanam">Udvartanam</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
              <img src="/images/images/clinic images-20240122T082942Z-001/clinicimages/shirodhara.jpg" alt="" /><br />
                <div className='text-overlay'>“ Serenity Pours, Mind Restores – Ayurvedic Bliss for Tranquil Mind and Total Well-being."</div>
                <HashLink className="hlink" to="/treatments#shirodhara">Shirodhara</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 rightside text-center">
            <div className='bg py-3'>
              <div className="serviceone1 rightside">
              <img src="/images/images/clinic images-20240122T082942Z-001/clinicimages/takradhara 1.png" /><br />
                <div className='text-overlay'>“ Nourish, Rejuvenate, Relax – Ayurvedic Bliss Cascading Like a Soothing Flow of Medicated Buttermilk."</div>
                <HashLink className="hlink" to="/treatments#takradhara">Takradhara</HashLink></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
                <img src="/images/images/newimages/drive-download-20240225T135402Z-001/pindaswedan.png" alt="" /><br />
                <div className='text-overlay'>“Harnessing the Power of Herbal Poultices and Rice Therapy for Deep Relaxation, Revitalization & healing of muscles & joints."</div>
                <HashLink className="hlink" to="/treatments#pindaSwedaPatraPotalliSweda">Pinda Sweda/ Patra Pottali Sweda</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
                <img src="/images/images/newimages/drive-download-20240225T135402Z-001/katibastii (2).png" /><br />
                <div className='text-overlay'>“Tailored Ayurvedic Bliss –  Targeted Therapy for Revitalizing the Lumbar Region and Nourishing the Neck."</div>
                <HashLink className="hlink" to="/treatments#katiBastiManyabasti">Kati & Manya Basti</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 rightside text-center">
            <div className='bg py-3'>
              <div className="serviceone1 rightside">
                <img src="/images/images/clinic images-20240122T082942Z-001/clinicimages/JanuBasti_KneePain-Treatment.jpg" /><br />
                <div className='text-overlay'>“ Nourish Your Knees, Nurture Your Mobility – Ayurvedic Care for Knee Health and Flexibility."</div>
                <HashLink className="hlink" to="/treatments#janubasti">Janu Basti</HashLink></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
                <img src="/images/images/clinic images-20240122T082942Z-001/clinicimages/hrudbasti.jpg" alt="" /><br />
                <div className='text-overlay'>“Nurture Your Heart, Embrace Vitality <br/>– Ayurvedic Care for Cardiovascular Wellness and Harmony."</div>
                <HashLink className="hlink" to="/treatments#katiBastiManyabasti">Hrid Basti</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
                <img src="/images/images/clinic images-20240122T082942Z-001/clinicimages/netra tarpan.jpg" /><br />
                <div className='text-overlay'>"See the World Through Ayurvedic Eyes – Netra Tarpana, Where Vision Meets Wellness."</div>
                <HashLink className="hlink" to="/treatments#netraTarpan">Netra Tarpan</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 rightside text-center">
            <div className='bg py-3'>
              <div className="serviceone1 rightside">
                <img src="/images/images/newimages/drive-download-20240225T135402Z-001/garbhasanskar.png" /><br />
                <div className='text-overlay'>“Nurturing Life Within, Ayurvedic Wisdom for a Healthy and Harmonious Pregnancy Journey."</div>
                <HashLink className="hlink" to="/treatments#garbhaSanskaar">Garbha Sanskaar</HashLink></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
                <img src="/images/images/newimages/drive-download-20240225T135402Z-001/suvarna prashan (2).png" alt="" /><br />
                <div className='text-overlay'>“Golden Drops of Ayurvedic Immunity – Fortify Your Child's Future Health."</div>
                <HashLink className="hlink" to="/treatments#suvarnaPrashan">Suvarna Prashan</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <div className='bg py-3'>
              <div className="serviceone1">
                <img src="/images/images/newimages/drive-download-20240225T135402Z-001/prewedding (2).png" /><br />
                <div className='text-overlay'>"Prep for the Perfect Day – Ayurvedic Bliss Awaits in Every Session."</div>
                <HashLink className="hlink" to="/treatments#preweddingpackage">Pre-Wedding Package</HashLink></div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4 rightside text-center">
            <div className='bg py-3'>
              <div className="serviceone1 rightside">
                <img src="/images/images/newimages/drive-download-20240225T135402Z-001/beejshuddhi.png" /><br />
                <div className='text-overlay'>"Seed the Future with Ayurvedic Purity – Beej Shuddhi, Nurturing Generations to Come."</div>
                <HashLink className="hlink" to="/treatments#beejShuddhiPackage">Beej Shuddhi Package</HashLink></div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />

<Contactus></Contactus>

    </>

  );
};
export default ServicesConnectionsection;