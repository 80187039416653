import React, {useState} from 'react';
import axios from 'axios';
import '../components/contactus.css';


function Contactus() {

    const [data,setData] = useState({
      name:"",
      email:"",
      number:"",
      query:""
    });
  
    const {name,email,number,query} = data;

    const handleChange = (e) => {
      setData({...data, [e.target.name]: e.target.value});
    }
    
      const handleSubmit = async (e) =>{
        e.preventDefault();

        try{
        const response = await fetch("https://v1.nocodeapi.com/jayesh0707/google_sheets/XFQMqDtQTxFqziYy?tabId=Sheet1",
        {
          method: 'POST',
          headers: {
            'Content-Type':'application/json'
          },
          body: JSON.stringify([[name,email,number,query]])
        });
        await response.json();
        setData({...data,name:"", email:"",number:"",query:""})
        }
        catch (err){
          console.log(err);
        }
      }

  
    
  

    return (
        <>
        <div className='container text-center lll'>
        <h3 className='text-center'>Connect Us</h3>
      <br />
      <form autoComplete='off' className='form-group' onSubmit={handleSubmit}>
        <input type="text" placeholder='Enter your Name' required
          className='form-control text'
          name='name' value={name} onChange={handleChange}></input>
        <br />
        <input type="text" placeholder='Enter Email Id' required
          className='form-control text'
          name='email' value={email} onChange={handleChange}></input>
        <br />
        <input type="text" placeholder='Enter Contact Number' required
          className='form-control text'
          name='number' value={number} onChange={handleChange}></input>
        <br />
        <input type="textbox" placeholder='Enter your Query' required
          className='form-control textbox'
          name='query' value={query} onChange={handleChange}></input>
        <br />
        <input type='submit' value='Submit'></input>
      </form>
      <br/>
      </div>
        </>
    );
}
export default Contactus;