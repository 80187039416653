import React, { useState } from 'react';
import '../components/treatments.css';


function Treatments() {

  return (
    <>
      <div className='boddy'>
        <div className="textcontainertreatments">
          <div className='topline'>Experience the rejuvenating influence of Ayurveda and Panchakarma with Achyutam Ayurveda & Panchakarma, revitalizing your mind, body, and soul.</div>

          {/* <div className='parent'>
            <div className='child1'><img className='image1' src='/images/pics/snehanswedana.png'></img></div>
            <div className='child2'><strong>Procedure :</strong>During this procedure, personalized medicated oils, tailored to the patient's body type and disease condition, are employed for a full-body massage. Following the massage, a steam bath is administered using medicated water (Kadha). This therapeutic process serves both relaxation purposes and is an integral part of Panchakarma.</div>
          </div>
          <strong>Benefits :</strong>The benefits of this procedure are manifold. It aids in reducing Vata, alleviating pain, dispelling tiredness, mitigating anxiety, and relieving muscle soreness. Additionally, it enhances the radiance of the skin from within, contributing to Sharir Kaanti. The treatment also restores the balance of local Doshas and provides nourishment to various body parts.
                As part of Panchakarma, this procedure plays a crucial role in bringing Doshas from throughout the body to the digestive tract. This facilitates their elimination through subsequent therapies such as Vaman, Virechan, and Basti, contributing to the overall cleansing and rejuvenation of the body.
           */}
          <p>
            {/* "Panchakarma," derived from Sanskrit, translates to "Five Therapies." These five therapeutic treatments, namely Vamana, Virechana, Nasya, Basti, and Raktamokshana, are meticulously designed to eliminate toxins from the body.
          To ensure optimal results, each Panchakarma therapy necessitates adherence to specific pre and post-therapeutic protocols, known as Purva Karma and Paschat Karma, complemented by carefully curated diet regimens.
          Embark on a journey of holistic well-being as you engage in these time-tested practices that purify and rejuvenate the body, fostering a profound sense of health and vitality. */}
            <br /><br />
            <div id="snehanswadan">
              <strong className='subheadings'>Abhyanga/Snehan - Swedan: </strong><br />
              <div><img className='image1' src='/images/pics/snehanswedana.png'></img></div>
              <div className='topspace'><strong>Procedure :</strong>During this procedure, personalized medicated oils, tailored to the patient's body type and disease condition, are employed for a full-body massage. Following the massage, a steam bath is administered using medicated water (Kadha). This therapeutic process serves both relaxation purposes and is an integral part of Panchakarma.</div>
              <div className='topspace'><strong>Benefits :</strong>The benefits of this procedure are manifold. It aids in reducing Vata, alleviating pain, dispelling tiredness, mitigating anxiety, and relieving muscle soreness. Additionally, it enhances the radiance of the skin from within, contributing to Sharir Kaanti. The treatment also restores the balance of local Doshas and provides nourishment to various body parts.
                As part of Panchakarma, this procedure plays a crucial role in bringing Doshas from throughout the body to the digestive tract. This facilitates their elimination through subsequent therapies such as Vaman, Virechan, and Basti, contributing to the overall cleansing and rejuvenation of the body.</div>
              <br />
            </div>
            <br /><br />
            <div id="udvartanam">
              <strong className='subheadings'>Udvartanam:</strong><br />
              <div><img className='image1' src='/images/pics/udwartanam.jpg'></img></div>
              <div className='topspace'><strong>Procedure :</strong>In this procedure, the first step involves a comprehensive oil massage applied to the entire body. Subsequently, a dry mix of powdered medicinal herbs is utilized for massaging the body, sometimes blended with oil or water based on the individual's body type.</div>
              <div className='topspace'><strong>Benefits :</strong>This versatile practice can be incorporated into one's daily routine (Nitya Karma) or reserved for special occasions such as pre-wedding body polishing. Its benefits include an enhanced inner radiance of the skin, skin cleansing, removal of dead skin, rejuvenation, reduction of fat and cellulites, the harmonization of Kapha Dosha, and instilling stability (Sthairya) to the body.
                Whether embraced as a daily ritual or reserved for special moments, this procedure contributes to overall skin health, providing a natural glow and addressing various aesthetic and wellness concerns.</div>
            </div>
            <br /><br />
            <div id="shirodhara">
              <strong className='subheadings'>Shirodhara:</strong><br />
              <div><img className='image1' src='/images/pics/shirodhara.webp'></img></div>
              <div className='topspace'><strong>Procedure :</strong>In this therapeutic procedure, warm medicated oils or ghee are gently poured onto the forehead, followed by a soothing massage. The duration of this calming treatment can range from 20 to 45 minutes, extending over a period of 3 to 21 days.</div>
              <div className='topspace'><strong>Benefits :</strong>The applications of this therapy are diverse and beneficial. It aids in calming the nerves, addressing scalp psoriasis, strengthening the sense organs, alleviating various mental stress and anxiety disorders, and providing relief for issues related to hair fall, dandruff, and premature greying.
                Indulge in this rejuvenating experience to promote overall well-being, addressing both physical and mental aspects for a balanced and revitalized state.</div>
            </div>
            <br /><br />
            <div id="takradhara">
              <strong className='subheadings'>Takradhara:</strong><br />
              <div><img className='image1' src='/images/pics/takradharaa.png'></img></div>
              <div className='topspace'><strong>Procedure :</strong>: In this therapeutic practice, medicated Takra (butter milk) is gently poured onto the forehead, with a duration ranging from 20 to 45 minutes over a period of 3 to 21 days.</div>
              <div className='topspace'><strong>Benefits :</strong>This specialized treatment offers notable benefits, particularly in addressing gastric issues such as gastritis, ulcers, irritable bowel syndrome (IBS), colitis, and digestive problems arising from stress. Immerse yourself in this healing experience to promote digestive well-being and alleviate discomfort associated with various gastrointestinal conditions.</div></div>
            <br /><br />
            <div id="pindaSwedaPatraPotalliSweda">
              <strong className='subheadings'>Pinda Sweda / Patra Potalli Sweda:</strong><br />
              <div><img className='image1' src='/images/pics/pinda sweda.png'></img></div>
              <div className='italic'>Pinda Sweda comes in various forms such as Patra Pinda Sweda and Shastik Shali Pinda Sweda, each employing distinct ingredients for their therapeutic effects.</div>
              <div className='topspace'><strong>Procedure :</strong>In the therapeutic practice of Shastik Shali Pinda Sweda, poultices (pottalis) made from Shastik Shali rice are cooked in special herbal concoctions with milk. These poultices are then continuously massaged onto the skin and joints while being simultaneously heated in the same herbal concoctions. This process is tailored to harness the benefits of Shastik Shali rice in addressing various musculoskeletal concerns.
                <br />Similarly, in Patra Pinda Sweda, poultices made from different medicinal leaves (Vanaspati leaves) are prepared and cooked in various medicated oils. These poultices are then concurrently massaged into specific body parts, combining the therapeutic properties of the herbal leaves and oils to address a diverse range of health issues.
                <br />Both techniques represent intricate and targeted applications of herbal poultices, employing the heat and herbal properties for their synergistic benefits in promoting healing, reducing inflammation, and improving overall well-being.
                <br /></div>
              <div className='topspace'><strong>Duration :</strong>This therapeutic procedure is typically employed for a duration ranging from 3 to 15 days.</div>
              <div className='topspace'><strong>Benefits :</strong>This treatment is notably beneficial for a wide range of conditions including spondylosis, spondylitis, residual hemiplegia, muscular atrophy, Parkinsonism, osteoarthritis, osteoporosis, back pain, body ache, cramps, sports injuries, paralysis, myalgia, neuralgia, sciatica, chronic pains, rheumatoid arthritis, and post-traumatic dysfunctions.
                It is particularly effective in improving the range of movements in joints, enhancing blood circulation, soothing nerves, and providing relief from neuralgic pain associated with nerve disorders.
                Pinda Sweda also aids in the elimination of toxins through sweating and contributes to the improvement of sensory and motor functions in individuals dealing with paralysis.
                Engage in this therapeutic practice to address a spectrum of musculoskeletal and neurological issues, promoting overall well-being.
              </div></div>
            <br /><br />
            <div id="janubasti">
              <strong className='subheadings'>Janu Basti:</strong><br />
              <div><img className='image1' src='/images/pics/Janu-Basti.webp'></img></div>
              <div className='italic'>This localized treatment is a specialized form of Basti focused on the knee joint and the surrounding musculature.</div>
              <div className='topspace'><strong>Procedure :</strong>In the therapeutic practice of Shastik Shali Pinda Sweda, poultices (pottalis) made from Shastik Shali rice are cooked in special herbal concoctions with milk. These poultices are then continuously massaged onto the skin and joints while being simultaneously heated in the same herbal concoctions. This process is tailored to harness the benefits of Shastik Shali rice in addressing various musculoskeletal concerns.</div>
              <div className='topspace'><strong>Duration :</strong>3-15 days</div>
              <div className='topspace'><strong>Benefits :</strong>This targeted procedure is particularly beneficial for addressing various knee-related issues such as knee bursitis (accumulation of fluid in the knee joint), sprained or strained ligaments, cartilage tears, tendonitis, arthritis, post-trauma issues, and degenerative disorders of the knee joint.
                The combination of hot oil application, sealing with dough, massage, and fomentation works synergistically to provide relief and support to the knee joint and its associated structures.
              </div>
            </div>
            <br /><br />
            <div id="katiBastiManyabasti">
              <strong className='subheadings'>Kati Basti, Manya Basti:</strong><br />
              <div><img className='image1' src='/images/pics/katibastii.png'></img></div>
              <div className='topspace'><strong>Procedure :</strong>In this specialized treatment, medicated hot oil is applied to the affected part, focusing on the lower back and neck area. The oil is held in place for a specific duration, sealed by dough. Subsequently, a gentle oil massage and hot fomentation follow.</div>
              <div className='topspace'><strong>Duration :</strong>3-15 days</div>
              <div className='topspace'><strong>Benefits :</strong>This therapeutic approach is designed to alleviate vitiated Vata from the lumbar or cervical region, providing relaxation to the muscles and significantly reducing backache , neck & shoulder pain.
                It proves beneficial for various conditions such as cervical spondylosis, lumbar spondylosis, slipped disc, and lumbago.
                The combination of medicated oil application, dough sealing, massage, and fomentation contributes to the relief and support of the affected area, promoting overall well-being.
              </div>
            </div>
            <br /><br />
            <div id="katiBastiManyabasti">
              <strong className='subheadings'>Hrid Basti [Heart – Hrid]:</strong><br />
              <div><img className='image1' src='/images/pics/hrudbasti.jpg'></img></div>
              <div className='topspace'><strong>Procedure :</strong>In this specific therapeutic procedure, medicated hot oil is applied to the pericardial part of the heart, held for a specified duration, and sealed with dough. Following this, a gentle oil massage is administered.</div>
              {/* <strong>Duration :</strong><br /> */}
              <div className='topspace'><strong>Benefits :</strong>:  This treatment is tailored to increase blood supply to the heart and its muscles, making it particularly beneficial for heart conditions dominated by Vata and Pitta, including palpitations.
                The procedure aims to enhance the strength of the heart, contributing to overall cardiovascular well-being.
                The combination of hot oil application, dough sealing, and massage is intended to provide targeted support to the pericardial region, promoting a healthy and resilient heart function.
              </div>
            </div>
            <br /><br />
            <div id="netraTarpan">
              <strong className='subheadings'>Netra Tarpan:</strong><br />
              <div><img className='image1' src='/images/pics/netratarpan.jpg'></img></div>
              <div className='topspace'><strong>Procedure :</strong>In this therapeutic procedure, lukewarm medicated ghee is applied to the eyes, allowing it to remain stagnant for a specified duration within a specially formed frame.
              </div>
              {/* <strong>Duration :</strong><br /> */}
              <div className='topspace'><strong>Benefits :</strong>This practice is beneficial for addressing various eye conditions, including refractive errors, dry eyes, conjunctivitis, watery eyes, computer vision syndrome, eye strain, cataract, night blindness, ptosis, and other related issues.
                The application of medicated ghee aims to provide relief, nourishment, and support to the eyes, promoting overall ocular health and alleviating specific eye-related concerns.
              </div></div>
            <br /><br />
            <div id="garbhaSanskaar">
              <strong className='subheadings'>Garbha Sanskaar:</strong><br />
              <div><img className='image1' src='/images/pics/garbhsansskar.png'></img></div>
              <div className='topspace'><strong>Garbh Sanskar, literally translating to "Education in the Womb," is a concept in Ayurveda that emphasizes the connection between the health of the mother and the well-being of the baby.</strong>
                <div className='topspace'>According to this practice, a woman is encouraged to modify her lifestyle to contribute positively to the mental and physical development of the baby. Key components include maintaining a healthy diet, engaging in regular exercise, fostering positive thoughts, and incorporating developmental Ayurvedic medicines.
                </div>
                <div className='italic'>The uses of Garbh Sanskar are manifold:
                  {/* <strong>Duration :</strong><br /> */}
                  <br />
                  <ul className="d">
                    <li>Improves the Health of Mother & Baby: The holistic approach promotes the overall well-being of both the mother and the developing baby.</li>
                    <li>Facilitates Development of Baby's Brain & Body: The practices involved are aimed at supporting the optimal growth and development of the baby's brain and body.</li>
                    <li>Reduces Complications in Pregnancy: Garbh Sanskar is believed to help in minimizing complications during pregnancy, lowering the chances of gestational disorders, and reducing the likelihood of post-pregnancy complications.</li>
                  </ul>
                </div>
                This approach underscores the significance of nurturing the mother's health and creating a positive and conducive environment for the developing baby, contributing to a healthy and harmonious pregnancy journey.
              </div></div>
            <br /><br />
            <div id="suvarnaPrashan">
              <strong className='subheadings'>Suvarna Prashan:</strong><br />
              <div><img className='image1' src='/images/pics/suvarnap.png'></img></div>
              <div className='italic'>Suvarna Prashana, an ancient Ayurvedic practice, is gaining recognition for its remarkable potential in enhancing the health and well-being of children.</div><br /> The benefits of Suvarna Prashana include:
              <ul className="d">
                <li><strong>Boosting Immunity:</strong>Strengthening the body's natural defense mechanisms.</li>
                <li><strong>Enhancing Cognitive Abilities:</strong>Supporting the development of mental faculties.</li>
                <li><strong>Improving Focus and Retention: </strong>Aiding in concentration and memory.</li>
                <li><strong>Promoting Healthy Skin: </strong>Contributing to the maintenance of skin health.</li>
                <li><strong>Aids in Digestion & Increasing Appetite: </strong>Supporting a healthy digestive system.</li>
                <li><strong>Optimizing Senses (Sense of Hearing, Vision): </strong>Fostering sensory development.</li>
                <li><strong>Behavioral Benefits (Calming and Stabilizing Behavior): </strong>Assisting in promoting balanced behavior.</li>
              </ul>
              Suvarna Prashana involves the use of Medhya medicines along with Gold Bhasma. Gold undergoes an extensive purification process and is subjected to high-temperature incineration, making it compatible with the human circulatory system. This makes Suvarna Prashana a safe and trusted practice, endorsed as a therapeutic option in clinical medicine.
              <br />Embark on your child's Suvarna Prashan journey now, providing them with a strong foundation for a healthy and thriving future.
            </div>
            <br /><br />
            <div id="preweddingpackage">
              <strong className='subheadings'>Prewedding package:</strong><br />
              <div><img className='image1' src='/images/pics/preewedding.png'></img></div>
              <div className='italic'>Indulge in our pre-wedding relaxation package, designed to bring forth a radiant glow from within and alleviate the soreness and fatigue resulting from the demands of pre-wedding shopping and rituals.</div> This comprehensive package spans 5 days of complete relaxation and includes the following rejuvenating sessions:<br />
              <div className='topspace'><ul className="d">
                <li><strong>Abhyanga, Udvartana, Swedan [Full Body] - 2 sessions: </strong>Experience the soothing touch of traditional Ayurvedic oil massage, invigorating herbal powder massage, and therapeutic steam therapy to rejuvenate your entire body.</li>
                <li><strong>Nasya - 5 sessions: </strong>Nasal therapy sessions to cleanse and rejuvenate the respiratory passages.</li>
                <li><strong>Matra Basti - 5 sessions:  </strong>Therapeutic herbal oil enemas to promote relaxation and balance.</li>
                <li><strong>Dhooma Pana, Gandoosh - 2 sessions: </strong>Inhalation therapy and herbal gargle sessions for respiratory and oral health.</li>
                <li><strong>Patra Pinda Sweda [Full Body] - 1 session:  </strong>Herbal leaf poultice massage for overall relaxation.</li>
                <li><strong>Netra Tarpana - 1 session:  </strong>Nourishing eye treatment for visual well-being.</li>
                <li><strong>Paad Abhyanga & Lepa - 1 session: </strong>Relaxing foot massage and herbal application for grounding.</li>
                <li><strong>Mukha Abhyanga & Lepa - 2 sessions: </strong>Facial massage and herbal application for facial rejuvenation.</li>
              </ul></div>
              All medicines used in these sessions are meticulously curated based on your individual body type and dosha prakriti, ensuring a personalized and revitalizing experience. Immerse yourself in these therapeutic sessions to prepare your mind and body for the joyous occasion ahead.</div>
            <br /><br />
            <br /><br />
            <div id="beejShuddhiPackage">
              <strong className='subheadings'>Beej Shuddhi Package :</strong><br />
              <div><img className='image1' src='/images/pics/beejashuddhi.png'></img></div>
              For couples planning pregnancy, it's crucial to recognize that the cumulative exposure to medicines, chemicals, pesticides, pollution, and genetically modified foods over time can potentially impact the quality of eggs and sperm. This may lead to genetic disorders and diseases that can be passed down through generations.
              <br />
              <div className='topspace'>In response to these concerns and with the goal of safeguarding the future child from such risks, as well as preparing them for a life of health and resilience, Beej Shuddhi is the recommended solution.
                This process involves Vaman (therapeutic emesis), Virechan (purgation), and Basti (therapeutic enema) tailored to the patient's dosha status.
              </div>
              <div className='topspace'><div className='italic'>By completing these major shodhanas (purification therapies), the body is facilitated in excreting vitiated doshas, clearing the path for the development of healthy new dhatus (tissues) such as rakta (blood), mansa (muscles), and meda (fat). These rejuvenated dhatus are crucial for reproduction as well as producing new healthy eggs & sperms.
                Additionally, this approach aids the prospective mother in bearing the child healthily for the entire nine months and supports a smoother delivery without complications or gestational disorders.
              </div>
                This purification process is designed to optimize the health and well-being of both prospective parents, ensuring a favorable environment for conception and the subsequent development of the child.
                Beej Shuddhi underscores the importance of purifying and strengthening the body before conception to lay the foundation for a healthy and vibrant future for both the parents and the child.

                In summary, Beej Shuddhi is a comprehensive and holistic practice that underscores the importance of preconception care, emphasizing the well-being of both the mother and the future child.
              </div>
            </div>
            <br /><br />
            <br />
          </p>
        </div>

        <div className="textcontainer1">
          <p>Experience the rejuvenating influence of Ayurveda and Panchakarma with Achyutam Ayurveda & Panchakarma, revitalizing your mind, body, and soul.
          </p>
        </div>
        <div className="whatsapp"><a href="https://api.whatsapp.com/send?phone=+918788083759">
          <img className="whatsappus" src="/images/WhatsappUs.png"></img></a></div>



      </div>
    </>

  );
}

export default Treatments;
