import './navbar.css';
import { Link } from "react-router-dom";
import { useState } from 'react';

function Navbar() {


  return (
    <>       
    {/* <Link className='' to='https://api.whatsapp.com/send?phone=+919082296568'><img className='homewhatsppus1' src="images/Whatsapp-icon.png"></img></Link> */}
      <nav className="navbar navbar-expand-sm ">
        <div className="container-fluid">
          <Link className="navbar-brand marginleftt" to="/"><img src="/images/achyutam_logo.png"></img></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <button className="leaf-button"></button>
          </button>
          <div className='divmobilelogo'>
            <img className="mobilelogo" src="/images/achyutam_logo.png"></img>
            </div>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll marginrightt" >
              {/* ms-auto manje margin start auto. manun te right la gela */}
              <li className="nav-item">
                <Link className="nav-link active me-2" aria-current="page" to="/" >Home</Link>
              </li>
              <li className="nav-item me-2">
                <Link className="nav-link" to="/aboutus">About Us</Link>
              </li>
              {/* <li className="nav-item me-2">
                <Link className="nav-link" to="/design2d3d">2D & 3D Design</Link>
              </li> */}
              {/* <li className="nav-item me-2">
                <Link className="nav-link" to="/aboutus" >FAQ</Link>
              </li> */}
              <li className="nav-item me-2">
                <Link className="nav-link" to="/treatments" >Treatments</Link>
              </li>
              <li className="nav-item me-2">
                <Link className="nav-link" to="/contactus" >Contact Us</Link>
              </li>
              <li className="nav-item me-2">
                <Link className="nav-link" to="/panchakarma">Panchakarma</Link>
              </li>
            </ul>

          </div>
        </div>
      </nav>
      {/* <div className='mainbox'>
        <div className='mainrightbox'>
          <h1 className='headingtext1'>Achyutam Ayurveda and</h1>
          <h1 className='headingtext2'>Panchkarma</h1>
        </div>
      </div> */}
    </>
  );
}

export default Navbar;
