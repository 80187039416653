import '../components/footer.css';

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="block" id="diva"></div>
        <div className="left1">
          <p className="address">
            <strong>Achyutam Ayurveda & Panchakarma</strong><br /><br />Farmazia Polyclinic,<br />Phase - 3, Hinjewadi,<br />Connect Us : 9082296568,<br />
            achyutamaayuveda@gmail.com
          </p>
        </div>
        {/* <div className='middle'>
        </div> */}
        <div className="right1">
        Our Timings<br /><br />
          Sunday to Friday:<br />
          Morning : 11:00 AM – 2:00 PM<br />
          Evening : 5:30 PM – 9:00 PM<br />
          (Satuday Closed)<br />

        </div>
      </div>
    </>
  );
}

export default Footer;
