import React from 'react';
import './App.css';
import First from './components/First';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import ServicesConnectionsection from './components/ServicesConnectionsection';
import Treatments from './components/treatments';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aboutus from './components/Aboutus';
import Contactus from './components/Contactus';
import View360 from './components/View360';
import Design2d3d from './components/Design2d3d';
import Panchakarma from './components/panchakarma';

function App() {
  return (
    <>
      <div className="col">
        {/* <img className="whatspplogo" src="https://img.icons8.com/color/48/000000/whatsapp--v1.png" width={40} height={40}>
        </img> */}
        <Router>
          <Navbar></Navbar>
          <Routes>
            <Route exact path="/" element={<First />}></Route>
            <Route exact path="/home" element={<First />}></Route>
            <Route exact path="/treatments" element={<Treatments />}></Route>
            <Route exact path="/aboutus" element={<Aboutus />}></Route>
            <Route exact path="/panchakarma" element={<Panchakarma />}></Route>
            <Route exact path="/contactus" element={<Contactus />}></Route>
            <Route exact path="/view360" element={<View360 />}></Route>
            <Route exact path="/design2d3d" element={<Design2d3d />}></Route>
          </Routes>
        </Router>
        <Footer />
      </div>
    </>
  );
}

export default App;
